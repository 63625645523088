import { announce } from "@/scripts/accessibility-helpers.js";

const DATA_TAGS_ENABLED = "tags";
const DATA_MAXIMUM_SELECTIONS = "maximum-selection-limit";

$(document).ready(function () {
  $("select[multiple]")
    .add("select.autocomplete")
    .each(function () {
      const tags = $(this).data(DATA_TAGS_ENABLED);
      const maximumSelectionLength = $(this).data(DATA_MAXIMUM_SELECTIONS);
      $(this).select2({
        tags,
        maximumSelectionLength,
        templateResult: function (result, container) {
          if (!result.id) {
            return result.text;
          }

          $(container).addClass("needsclick");
          return result.text;
        },
      });
    });

  let select2Multiples = $(".select2-hidden-accessible[multiple]");
  select2Multiples.each((index, el) => {
    let surveyQuestionWrapper = $(el).closest(".survey_question");
    let visibleLabel = $(surveyQuestionWrapper).find("label");
    let searchField = $(surveyQuestionWrapper).find(".select2-search__field");
    $(searchField).attr("aria-label", $(visibleLabel).text());
    $(el).on("select2:open", () => {
      let results = $(".select2-results__options");
      $(searchField).on("keyup", () => {
        let currentOption = $(results).find(
          ".select2-results__option--highlighted"
        );
        announce($(currentOption).text());
      });
    });
  });

  let select2Singles = $(".select2-hidden-accessible").not("[multiple]");
  select2Singles.each((index, el) => {
    let surveyQuestionWrapper = $(el).closest(".survey_question");
    let visibleLabel = $(surveyQuestionWrapper).find("label");
    let displayedSelection = $(surveyQuestionWrapper).find(
      ".select2-selection--single"
    );
    $(displayedSelection).attr("aria-labelledby", el.id);
    $(el).on("select2:open", () => {
      let searchField = $(".select2-search__field");
      $(searchField).attr("aria-label", visibleLabel.text());
      let results = $(".select2-results__options");
      $(searchField).on("keyup", () => {
        let currentOption = $(results).find(
          ".select2-results__option--highlighted"
        );
        announce($(currentOption).text());
      });
    });
  });

  $("select[multiple]")
    .add("select.autocomplete")
    .each(function (index, el) {
      $($(el).data("select2")).find("*").addClass("needsclick");
    });

  $('select[data-select="redirect"]').on("change", function () {
    window.location.href = $(this).val();
  });
});
